import React from 'react';

import DefaultLayout from '../components/templates/DefaultLayout';
import SEO from '../components/atoms/SEO/SEO';
import Section from '../components/molecules/Section/Section';
import { graphql, useStaticQuery } from 'gatsby';
import InternalLink from '../components/atoms/InternalLink/InternalLink';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query errorPageLinks {
      menu: contentfulMenu(menuLocation: {eq: "Header"}) {
        items: menuItems {
          id
          title
          content {
            slug
          }
        }
      }
    }
  `);
  return (
      <DefaultLayout>
        <SEO title="404: Not found" />
        <Section title="Oops!" lead="We can't seem to find the page you are looking for.">
          <p className="-mt-16 text-gray-700 text-lg font-thin">
            Here are some helpful links instead:
            <ul className="mt-2">
              {
                data.menu.items.map(item => (
                  <li>
                    <InternalLink to={item.content.slug}>{item.title}</InternalLink>
                  </li>
                ))
              }
            </ul>
          </p>
        </Section>
      </DefaultLayout>
  );
};

export default NotFoundPage;
